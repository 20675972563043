import { useContext, useState } from 'react';
import { UserContext } from '../../auth/userSession';
import './Nav.css';
import NavItem from './NavItem';
import NavUser from './NavUser';
import UserInfo from './UserInfo';
import { faGamepad, faClapperboard, faTv, faGears } from '@fortawesome/free-solid-svg-icons'

function Nav(props) {
    const [showUserInfo, setShowUserInfo] = useState(false)
    const ctx = useContext(UserContext)

    const onClickHandler = () => {
        setShowUserInfo(true)
    }

    return (
        <nav className="navbar">
            <NavItem href="/" logo />
            <div className="nav-items">
                <NavItem icon={faGamepad} allow="games" href="/games">Games</NavItem>
                <NavItem icon={faGears} allow="apps" href="/apps">Apps</NavItem>
                {/* <NavItem icon={faClapperboard} allow="media" href="/movies">Movies</NavItem> */}
                {/* <NavItem icon={faTv} allow="media" href="/shows">Shows</NavItem> */ }
            </div>
            <NavUser 
                userInfo={props.user}
                className="nav-user"
                style={{textDecoration: 'none', color: 'var(--foreground)'}} 
                onClick={onClickHandler}
                />
            { 
            showUserInfo 
            ? 
                <UserInfo user={props.user} onClose={() => setShowUserInfo(false)} onLogOut={() => ctx.logOut()}/>
            : 
                null
            }
        </nav>
    );
}

export default Nav;

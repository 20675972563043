import { getUserIconPath } from "../../api/users/users"
import "./UserInfo.scoped.css"

function UserInfo(props) {
    const user = props.user
    const closed = props.onClose
    const logout= props.onLogOut

    return (
        <div className="overlay user-info-container">
            <div className="user-info slide-in">
                <div className="close" onClick={closed}>X</div>
                <div className="user-icon">
                    <img src={getUserIconPath(user)} />
                </div>
                <div className="username">{user.displayName}</div>
                <div className="logout" onClick={logout}>Logout</div>
            </div>
        </div>
    )
}

export default UserInfo